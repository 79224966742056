<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('advisored_of_students')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('advisored_of_students')"
                              :isFilter="false"/>
            </template>
            <datatable-filter v-show="true"
                              @filter="searchStudent"
                              @filterClear="filterClear"
                              :exportExcel="true"
                              @exportExcel="downloadExcel">
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                            <b-col sm="6" md="4" lg="3" xl="2" >
                                <b-form-group :label="$t('name')">
                                    <b-form-input size="sm" v-model="filter.name"></b-form-input>
                                </b-form-group>
                            </b-col>

                            <b-col sm="6" md="4" lg="3" xl="2">
                                <b-form-group :label="$t('surname')">
                                    <b-form-input size="sm" v-model="filter.surname"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="4" lg="3" xl="2">
                                <b-form-group :label="$t('student_number')">
                                    <b-form-input size="sm" v-model="filter.student_number"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="4" lg="3" xl="2">
                                <b-form-group :label="$t('program')">
                                    <b-form-select v-model="filter.program_name" :options="programOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col sm="6" md="3" lg="2" xl="2">
                                <b-form-group :label="$t('class')">
                                    <parameter-selectbox v-model="filter.class_name" code="classes" :valueType="'name'"/>
                                </b-form-group>
                            </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>

            <div class="row mb-4">

                <div class="w-100 d-none d-lg-block"></div>
                <div class="col-12 col-lg-3 col-xxl-3">
                    <div class="d-flex justify-content-around mb-3">
                            <b-dropdown right split :text="this.$t('name')" size="sm"  variant="light">
                                <b-dropdown-item @click="short('name_asc')">{{this.$t('short_name_asc')}}</b-dropdown-item>
                                <b-dropdown-item @click="short('name_desc')">{{ this.$t('short_name_desc') }}</b-dropdown-item>
                            </b-dropdown>

                            <b-dropdown right split :text="this.$t('student_number')" size="sm" variant="light">
                                <b-dropdown-item @click="short('number_asc')">{{ this.$t('short_number_asc') }}</b-dropdown-item>
                                <b-dropdown-item @click="short('number_desc')">{{ this.$t('short_number_desc') }}</b-dropdown-item>
                            </b-dropdown>
                    </div>

                    <!--					<b-input-group size="lg" class="mb-2">-->
                    <!--						<b-input-group-prepend is-text>-->
                    <!--							<i class="ri-search-line"></i>-->
                    <!--						</b-input-group-prepend>-->
                    <!--						<b-form-input-->
                    <!--							type="search"-->
                    <!--							:placeholder="$t('search')"-->
                    <!--							@input="searchStudent"-->
                    <!--						></b-form-input>-->
                    <!--					</b-input-group>-->

                    <div class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100"
                         style="height: calc(100vh - 220px)"
                    >
                        <div v-if="isStudents === true">
                            <div v-for="(item, key) in students" v-bind:key="key">
                                <b-button
                                    :variant="
                      (current_student === item.student_program_id
                        ? 'outline-primary'
                        : 'outline-secondary text-dark') +
                      ' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 flex-column align-items-start h-auto'
                    "
                                    @click="getStudent(item)"
                                >
                                    <span>{{ toUpperCase(item.name + "  " + item.surname) }}</span>
                                    <span class="text-muted">{{ item.student_number }}</span>
                                </b-button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="spinner-border text-light mx-auto d-flex"></div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 col-xxl-9">
                    <div class="w-100" v-if="showStudent == true">
                        <h5 class="mt-1 mb-3">
                            {{ current_student_data.name + " " + current_student_data.surname }}
                        </h5>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('student_number')">
                                    {{ current_student_data.student_number }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('email')">
                                    {{ current_student_data.email }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('gsm_no')">
                                    {{ current_student_data.mobile_tel }}
                                </b-form-group>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('class')">
                                    {{ current_student_data.class_name }}
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('student_status')">
                                    {{ this.getLocaleText(current_student_data, 'student_status_name') }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('semester_status')">
                                    {{ this.getLocaleText(current_student_data, 'semester_status_name') }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('faculty_name')">
                                    {{ this.getLocaleText(current_student_data, 'faculty_name') }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('program_name')">
                                    {{ this.getLocaleText(current_student_data, 'program_name') }}
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('cgpa')">
                                    {{ current_student_data.cgpa }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('credits_earned')">
                                    {{ current_student_data.credits }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('ects_credits_earned')">
                                    {{ current_student_data.ects_credits }}
                                </b-form-group>
                            </div>

                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                                <b-form-group :label="$t('payment_status')">
                                    {{ this.$t('payment_status_' + current_student_data.payment_status) }}
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <router-link :to="'/student/programs/'+current_student_data.student_program_id+'/slots'"
                                             class="btn btn-outline-primary">
                                    {{ $t('slots').toUpper() }} <i class="ri-arrow-right-line"></i>
                                </router-link>

                                <b-button variant="outline-primary ml-3"
                                          @click="downloadTranscript(current_student_data)">
                                    <span class="d-flex">{{ $t('transcript').toUpper() }} <i
                                        class="ri-arrow-right-line"></i></span>
                                </b-button>

                                <b-button variant="outline-primary ml-3"
                                          @click="downloadCourseSchedule(current_student_data)">
                                    <span class="d-flex">{{ $t('course_schedule').toUpper() }} <i
                                        class="ri-arrow-right-line"></i></span>
                                </b-button>

                                <b-button variant="outline-primary ml-3"
                                          @click="downloadExamSchedule(current_student_data)">
                                    <span class="d-flex">{{ $t('exam_schedule1').toUpper() }} <i
                                        class="ri-arrow-right-line"></i></span>
                                </b-button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

// Services
import StudentProgramService from "@/services/StudentProgramService";
import StudentDocumentService from "@/services/StudentDocumentService";
import StaffAutoSuggest from "@/components/interactive-fields/StaffAutoSuggest.vue";
import MultiText from "@/components/interactive-fields/MultiText.vue";

export default {
    components: {
        MultiText, StaffAutoSuggest,
        AppLayout,
        Header,
        HeaderMobile,
        ParameterSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("advisored_of_students"),
        };
    },
    data() {
        return {
            isStudents: false,
            students: [],
            royalStudents: [],
            current_student: null,
            current_student_data: {},
            showStudent: false,
            filter: {},
            programOptions:[]
        };
    },
    watch: {},

    computed: {},

    methods: {
        turkishStringToUpperCase(string) {
            let letters = {i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I"};
            string = string.replace(/(([iışğüçö]))/g, function (letter) {
                return letters[letter];
            });
            return string.toUpperCase();
        },

        filterClear() {
            this.filter = {}
            this.students = this.royalStudents;
        },
        short(key){
            return this.students.sort((a,b)=>{
                if (key==='name_asc'){
                    return a.name.localeCompare(b.name);
                }else if(key==='name_desc'){
                    return b.name.localeCompare(a.name);
                }else if (key==='number_asc'){
                    return a.student_number.localeCompare(b.student_number);
                }else if (key==='number_desc'){
                    return b.student_number.localeCompare(a.student_number);
                }
            })
        },
        searchStudent() {
            let filterKeys = Object.keys(this.filter);
            let filteredArray = [];
            if (filterKeys.length === 0) {
                // Eğer filtre objesi boş ise, tüm öğrencileri göster
                this.students = this.royalStudents;
                return;
            }
            //arama yapıldıktan sonra değerlerin hepsi silindikten sonra dizi tekrar doldurulur
            filterKeys.every(key => {
                if (!this.filter[key]) {
                    this.students = this.royalStudents;
                }
            })
            this.students.forEach(student => {
                let matchesFilter = filterKeys.every(key => {
                    let filterValue = this.turkishStringToUpperCase(this.filter[key]);
                     return student.student_number.includes(filterValue) ||
                      student.name.includes(filterValue) || 
                      student.surname.includes(filterValue) || 
                       student.program_name.includes(filterValue) ||
                       student.class_name.includes(filterValue)
                });
                if (matchesFilter) {
                    filteredArray.push(student);
                }
            });
            if (!filteredArray.length) {
                this.students = this.royalStudents
                this.students.forEach(student => {
                    let matchesFilter = filterKeys.every(key => {
                        let filterValue = this.turkishStringToUpperCase(this.filter[key]);
                        return student.student_number.includes(filterValue) ||
                            student.name.includes(filterValue) ||
                            student.surname.includes(filterValue) ||
                            student.program_name.includes(filterValue)||
                            this.turkishStringToUpperCase(student.class_name).includes(filterValue)
                    });
                    if (matchesFilter) {
                        filteredArray.push(student);
                    }
                });
            }
            this.students = filteredArray;
        },

        getStudent(item) {
            this.showStudent = true;
            this.current_student_data = item;
        },

        async getStudents() {
            this.isStudents = false;
            StudentProgramService.getAdvisorStudents().then((response) => {
                if (response.data.success) {
                    this.isStudents = true;
                    this.royalStudents = response.data.data;
                    this.students = response.data.data;

                    this.students.forEach(itm=>{
                        if (!this.programOptions.some(option=>option.value===itm.program_name)){
                            this.programOptions.push({
                                text:this.$i18n.locale==='tr'?itm.program_name:itm.program_name_en,
                                value:itm.program_name
                            })
                        }
                    })
                }
            });
        },

        downloadTranscript(row) {
            if (this.checkPermission('studentdocument_transcript')) {
                StudentDocumentService.downloadTranscript(row.student_program_id, this.$i18n.locale)
                    .then(response => {
                        this._downloadFile(response, row.student_number + '-' + this.$t('transcript') + '.pdf')
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        downloadCourseSchedule(row) {
            if (this.checkPermission('studentdocument_courseschedule')) {
                StudentDocumentService.downloadStudentCourseSchedule(row.student_program_id, this.$i18n.locale).then(response => {
                    this._downloadFile(response, row.student_number + '-' + this.$t('course_schedule') + '.pdf')
                }).catch(e => {
                    this.showErrors(e)
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        downloadExamSchedule(row) {
            if (this.checkPermission('studentdocument_examschedule')) {
                StudentDocumentService.downloadStudentExamSchedule(row.student_program_id, this.$i18n.locale).then(response => {
                    this._downloadFile(response, row.student_number + '-' + this.$t('exam_schedule1') + '.pdf')
                }).catch(e => {
                    this.showErrors(e)
                })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        downloadExcel() {
           const headers = [
                { header: this.$t('name'), key: "name", width: 20 },
                { header: this.$t('surname'), key: "surname", width: 20 },
                { header: this.$t('student_number'), key: "student_number", width: 20 },
                { header: this.$t('email'), key: "email", width: 20 },
                { header: `${this.$t('gsm_no')}`, key: "mobile_tel", width: 20 },
                { header: `${this.$t('class')}`, key: "class_name", width: 20 },
                { header: `${this.$t('student_status')}`, key: "student_status_name", width: 20 },
                { header: this.$t('semester_status'), key: "semester_status_name", width: 20 },
                { header: this.$t('faculty_name'), key: "faculty_name", width: 20},
                { header: this.$t('program_name'), key: "program_name", width: 20 },
                { header: this.$t('cgpa'), key: "cgpa", width: 20 },
                { header: this.$t('credits_earned'), key: "credits", width: 20 },
                { header: this.$t('ects_credits_earned'), key: "ects_credits", width: 20 },
                { header: this.$t('payment_status'), key: "payment_status", width: 20 },


            ]
            this._downloadExcelFile({ data: this.students, filename : `${this.$t('advisored_of_students')}.xlsx`, headers})
        },
    },
    created() {
        this.getStudents();
    },
    mounted() {
    }
}
</script>
<style scoped>

</style>
